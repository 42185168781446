























import Vue from 'vue'
import BaseLabelTag from '../../../components/BaseLabelTag.vue'
export default Vue.extend({
  name: 'PreviewBlock',
  data () {
    return {
      loading: false
    }
  },
  props: [
    'strategy'
  ],
  components: {
    BaseLabelTag
  },
  methods: {
    redirect () {
      this.$emit('redirect', { id: Number(this.strategy.id) })
    }
  }
})
