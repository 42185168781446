

















































import Vue from 'vue'
import VSelect from '../../components/BaseComponents/VSelect/VSelect.vue'
import PreviewBlock from '../../components/strategies/StrategiesRules/PreviewBlock.vue'
import BasePagePreloader from '../../components/BasePagePreloader.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  STRATEGY_TYPE_ESSENTIAL,
  STRATEGY_TYPE_OPTIMISE,
  STRATEGY_TYPE_PAUSE,
  STRATEGY_TYPE_SCALE,
  STRATEGY_TYPE_TIME,
  STRATEGY_TYPE_TITLES
} from '@/constants/FbAutomatedRule'
export default Vue.extend({
  name: 'SelectStrategy',
  data () {
    return {
      loading: true,
      group: null
    }
  },
  components: { VSelect, PreviewBlock, BasePagePreloader },
  props: {
    service: {
      required: true,
      type: String
    }
  },
  methods: {
    ...mapActions('strategy', ['loadStrategies', 'loadGroups']),
    ...mapActions('adService', ['setService']),
    filterStrategies (type) {
      return this.strategies.filter(v => v.type.toLowerCase() === type.toLowerCase() && v.group.indexOf(this.group.code) !== -1)
    },
    select_group (event) {
      this.group = event
    },
    strategy_type_title (t) {
      return STRATEGY_TYPE_TITLES[t]
    }
  },
  computed: {
    ...mapState('strategy', ['strategies']),
    ...mapGetters('strategy', ['groups']),
    ...mapGetters('adService', ['getServiceInclude']),
    strategy_types () {
      return [
        STRATEGY_TYPE_ESSENTIAL,
        STRATEGY_TYPE_SCALE,
        STRATEGY_TYPE_PAUSE,
        STRATEGY_TYPE_OPTIMISE,
        STRATEGY_TYPE_TIME
      ]
    },
    groups_options () {
      if (!this.groups) {
        return []
      }
      return Object.entries(this.groups).map(([k, v]) => {
        return {
          label: v,
          code: k
        }
      })
    }
  },
  mounted () {
    this.setService(this.service)
    Promise.all([
      this.loadStrategies({
        'include[]': this.getServiceInclude
      }),
      this.loadGroups()
    ]).then(() => {
      this.group = this.groups_options[0]
      setTimeout(() => { this.loading = false }, 1000)
    })
  }
})
